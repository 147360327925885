<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Retenção
        </v-card-title>
        <v-simple-table>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.text"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody
            v-if="load.status"
          >
            <tr>
              <td :colspan="headers.length">
                <v-progress-linear
                  indeterminate
                />
                <v-row justify="center">
                  <v-subheader>
                    {{ load.message }}
                  </v-subheader>
                </v-row>
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
          >
            <tr
              v-for="item in retentions"
              :key="item"
            >
              <td
                v-for="i in headers"
                :key="i"
                :class="item[i.class]"
              >
                {{ item[i.value] }}
                <template v-if="i.text === 'OPÇÕES'">
                  <v-btn
                    :loading="item.id === loadModal? true : false"
                    fab
                    color="info"
                    x-small
                    @click="getContractInfo(item)"
                  >
                    <v-icon
                      small
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row>
          <v-col cols="12">
            <v-pagination
              v-model="page"
              :length="totalPages"
              total-visible="5"
              circle
              @input="next"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axiosIns from '@axios'

export default {
  data() {
    return {
      page: 1,
      headers: [
        { text: 'MÊS', value: 'month' },
        { text: 'VENDA', value: 'month' },
        { text: 'PARC 01', value: 'parcel_1' },
        { text: 'PARC 02', value: 'parcel_2' },
        { text: 'PARC 03', value: 'parcel_3' },
        { text: 'PARC 04', value: 'parcel_4' },
        { text: 'PARC 05', value: 'parcel_5' },
        { text: 'PERC PARC 4', value: 'month' },
        { text: 'PERC PARC 5', value: 'month' },
        { text: 'RETENÇÃO', value: 'month' },
      ],
      retentions: [],
      totalPages: 0,
      load: {
        status: false,
        message: '',
      },
    }
  },
  created() {
    this.next()
  },
  methods: {
    async next() {
      this.load = {
        status: true,
        message: 'Carregando dados... Por favor, aguarde',
      }
      await axiosIns
        .get(`/api/v1/integration/retention/index?page=${this.page}`)
        .then(res => {
          res.data.data.map(dt => {
            this.retentions.push({
              id: dt.id,
              month: dt.reference ? dt.reference : '',
              parcel_1: dt.parcel_1 ? dt.parcel_1 : '',
              parcel_2: dt.parcel_2 ? dt.parcel_2 : '',
              parcel_3: dt.parcel_3 ? dt.parcel_3 : '',
              parcel_4: dt.parcel_4 ? dt.parcel_4 : '',
              parcel_5: dt.parcel_5 ? dt.parcel_5 : '',
            })
            this.load.status = false
            this.totalPages = res.data.meta.last_page
          })
        })
        .catch(() => {
          this.load = {
            status: true,
            message: 'Erro ao buscar dados... Por favor, recarregue a página',
          }
        })
    },
  },
}
</script>
